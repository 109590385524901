// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-destinos-hotel-casa-maya-holbox-index-js": () => import("./../../../src/pages/destinos/hotel-casa-maya-holbox/index.js" /* webpackChunkName: "component---src-pages-destinos-hotel-casa-maya-holbox-index-js" */),
  "component---src-pages-destinos-hotel-casa-maya-holbox-payment-js": () => import("./../../../src/pages/destinos/hotel-casa-maya-holbox/payment.js" /* webpackChunkName: "component---src-pages-destinos-hotel-casa-maya-holbox-payment-js" */),
  "component---src-pages-destinos-hotel-pier-bacalar-index-js": () => import("./../../../src/pages/destinos/hotel-pier-bacalar/index.js" /* webpackChunkName: "component---src-pages-destinos-hotel-pier-bacalar-index-js" */),
  "component---src-pages-destinos-hotel-pier-bacalar-payment-js": () => import("./../../../src/pages/destinos/hotel-pier-bacalar/payment.js" /* webpackChunkName: "component---src-pages-destinos-hotel-pier-bacalar-payment-js" */),
  "component---src-pages-destinos-hotel-xo-bacalar-index-js": () => import("./../../../src/pages/destinos/hotel-xo-bacalar/index.js" /* webpackChunkName: "component---src-pages-destinos-hotel-xo-bacalar-index-js" */),
  "component---src-pages-destinos-hotel-xo-bacalar-payment-js": () => import("./../../../src/pages/destinos/hotel-xo-bacalar/payment.js" /* webpackChunkName: "component---src-pages-destinos-hotel-xo-bacalar-payment-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-package-js": () => import("./../../../src/pages/package.js" /* webpackChunkName: "component---src-pages-package-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-pages-tours-bacalar-js": () => import("./../../../src/pages/tours-bacalar.js" /* webpackChunkName: "component---src-pages-tours-bacalar-js" */),
  "component---src-pages-tours-holbox-js": () => import("./../../../src/pages/tours-holbox.js" /* webpackChunkName: "component---src-pages-tours-holbox-js" */),
  "component---src-templates-hotel-page-js": () => import("./../../../src/templates/hotel-page.js" /* webpackChunkName: "component---src-templates-hotel-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-room-page-js": () => import("./../../../src/templates/room-page.js" /* webpackChunkName: "component---src-templates-room-page-js" */)
}

